import React from 'react'
import Button from 'react-bootstrap/Button';


export default function Spotify() {

    const spotifyUrl = 'https://accounts.spotify.com/authorize';
    const redirectUri = process.env.REACT_APP_REDIRECT;
    const clientId = "8201b373732c49cab95fbfcc6fc92fde"

    const scopes = ['playlist-modify-private', 'playlist-read-private','playlist-modify-public']

    const loginurl = spotifyUrl + '?client_id=' + clientId + '&redirect_uri=' + redirectUri + '&scope=' + scopes.join("%20") + '&response_type=token&show_dialog=true'

  return (
    <div>

    <Button variant="primary" href={loginurl}> Login en Spotify</Button>{' '}

    </div>
  )
}
