import React from 'react'
import { useParams, useResolvedPath } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export default function Spotifyauth(props) {

    const datos = window.location.hash.substring(1)
    .split("&")[0].split("=");
    console.log(datos)


    var axios = require('axios');
    const[dataspotify, setData]=useState({})
    const[dataspotifydos, setDatados]=useState({})



const getData = () =>{

    var config = {
        method: 'get',
        url: 'https://api.spotify.com/v1/playlists/4AHISWZyjVEzrqESIaUu0C',
        headers: { Authorization: 'Bearer ' + datos[1]}
        
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

}

const getDatados = () =>{

  var config = {
      method: 'get',
      url: 'https://api.spotify.com/v1/playlists/79L7keEcfRMsdbBP5ip1Db',
      headers: { Authorization: 'Bearer ' + datos[1]}
      
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setDatados(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

}

const exportData = () => {




  var config2 = {
    method: 'put',
    url: 'https://back955.vercel.app/?playlist=4AHISWZyjVEzrqESIaUu0C',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : dataspotify
  };
  
  axios(config2)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
  

  var config3 = {
    method: 'put',
    url: 'https://back955.vercel.app/?playlist=79L7keEcfRMsdbBP5ip1Db',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : dataspotifydos
  };
  
  axios(config3)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });


};

useEffect(() => {
    getData()
    getDatados()
},[] )

  return (
    <div>

        Los datos de Spotify



       <button type="button" onClick={exportData}>
        Actualizar los datos
      </button>

    </div>
  )
}
