import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { height } from '@mui/system';

export default function Item(props) {

    const data = props.data;
  return (
    <>



        
        
        
        <div className='music-card'>
        
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    {(() => {
        if (props.posicion == "1") {
          return (
            <h2 className="position">{props.posicion} ⭐️</h2> 
          ) 
        } else{

            <h2 className="position">{props.posicion}</h2> 
        }
})()}
    <h2 className="position">{props.posicion}</h2> 
    <div className='image'>
    
    
    <img className='img-item' src={data.track.album.images[1].url} alt={'Foto del album' + data.track.album.name} />
    </div>
    
    <div className='info'>
  
        <h2 className='title'>{data.track.name}</h2>
        <h2 className='artist'><a className="link_artista" href={data.track.artists[0].external_urls.spotify}> {data.track.artists[0].name}</a></h2>

    </div>
    
    </div>
        
  
    </>
  )
}
