import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Spotify from './components/Spotify';
import Spotifyauth from './components/Spotifyauth';
import View from './components/View';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import Viewdos from './components/Viewdos';

function App() {
  return (
<div className='content'>
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Spotify/>} />
      <Route path="/redirect" element={<Spotifyauth/>} />
      <Route path="/view" element={<View/>} />
      <Route path="/viewdos" element={<Viewdos/>} />
      </Routes>
    </BrowserRouter>
  </div>
  );
}



export default App;
